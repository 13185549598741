<template>
  <CompetitionsTable/>
</template>
<script>
import CompetitionsTable from '@/views/ziqni/competitions/CompetitionsTable.vue';

export default {
  components: {
    CompetitionsTable
  },
  props: {}
};
</script>